<div (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
  <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" [(selectedIndex)]="selectedTab">
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">event</mat-icon> -->
        Aktuell
      </ng-template>

      <div *ngFor="let data of currentEvents; let i = index" class="event-view">
        <event-view [data]="data" [users]="users" [userOptions]="userOptions" [accountingYear]="accountingYear"
          [viewMode]="viewMode" (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"></event-view>
      </div>
      <div *ngIf="currentEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">event_upcoming</mat-icon> -->
        Zukunft
      </ng-template>

      <div *ngFor="let data of futureEvents; let i = index" class="event-view">
        <event-view [data]="data" [users]="users" [userOptions]="userOptions" [accountingYear]="accountingYear"
          [viewMode]="viewMode" (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"></event-view>
      </div>
      <div *ngIf="futureEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">done</mat-icon> -->
        Beendet
      </ng-template>

      <div *ngFor="let data of pastEvents; let i = index" class="event-view">
        <event-view [data]="data" [users]="users" [userOptions]="userOptions" [accountingYear]="accountingYear"
          [viewMode]="viewMode" (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"></event-view>
      </div>
      <div *ngIf="pastEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label> Schicht-Übersicht </ng-template>

      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-form-field appearance="fill" style="
            padding-top: 20px;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 20px;
          ">
          <mat-label>Event auswählen</mat-label>
          <mat-select [formControl]="eventControl">
            <mat-option *ngFor="let event of allEvents" [value]="event.id">
              {{ event.name }} {{ shiftIsPassed(event) ? "(beendet)" : "" }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-slide-toggle class="footer-icon" style="padding-left: 5%;" matTooltip="Namen ausblenden" color="primary"
          (change)="onChangeShowNames($event)" [checked]="showNames" *ngIf="selectedEvent">Namen
          ausblenden</mat-slide-toggle>

        <hr />

        <div *ngIf="selectedEvent" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px"
          style="padding: 2%">
          <mat-card style="
              text-align: center;
              background-color: lightgrey;
              margin-bottom: 20px;
            ">
            <h2>{{ selectedEvent.name }}</h2>
            <p>{{ selectedEvent.description }}</p>
          </mat-card>

          <div *ngFor="let day of getShiftsByDay(selectedEvent.workPackages)" fxLayout="column" fxLayoutGap="10px">
            <h3>{{ day.day }}</h3>
            <table mat-table [dataSource]="day.shifts" class="mat-elevation-z8 responsive-table">
              <!-- Shift Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                  Schicht-Name
                </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  <b>{{ shift.type }} </b></mat-cell>
              </ng-container>

              <!-- Shift Organizer Column -->
              <ng-container matColumnDef="responsiblePerson">
                <mat-header-cell *matHeaderCellDef>
                  Schichtleiter
                </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  {{ shift.responsiblePerson }}
                </mat-cell>
              </ng-container>

              <!-- Shift Start Column -->
              <ng-container matColumnDef="start">
                <mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  {{ shift.start.toDate() | date : "HH:mm" }} Uhr
                </mat-cell>
              </ng-container>

              <!-- Shift End Column -->
              <ng-container matColumnDef="end">
                <mat-header-cell *matHeaderCellDef> Ende </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  {{ shift.end.toDate() | date : "HH:mm" }} Uhr
                </mat-cell>
              </ng-container>

              <!-- Free places Column -->
              <ng-container matColumnDef="freePlaces">
                <mat-header-cell *matHeaderCellDef> Freie Plätze </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  {{ calcFreePlaces(shift) }}
                </mat-cell>
              </ng-container>

              <!-- Shift Participants Column -->
              <ng-container matColumnDef="participants">
                <mat-header-cell *matHeaderCellDef>
                  Teilnehmer
                </mat-header-cell>
                <mat-cell *matCellDef="let shift">
                  <div class="participants-container">
                    <div *ngFor="
                        let i of [].constructor(shift.minParticipants);
                        let idx = index
                      " class="participant-cell" [ngClass]="{
                        occupied: shift.participants && shift.participants[idx],
                        empty: !(shift.participants && shift.participants[idx])
                      }">
                      <div *ngIf="!hideNames">
                        {{
                        shift.participants && shift.participants[idx]
                        ? shift.participants[idx].firstName +
                        " " +
                        shift.participants[idx].lastName
                        : "Nicht belegt"
                        }}
                      </div>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="[
                  'name',
                  'responsiblePerson',
                  'start',
                  'end',
                  'freePlaces',
                  'participants'
                ]"></mat-header-row>
              <mat-row *matRowDef="
                  let row;
                  columns: [
                    'name',
                    'responsiblePerson',
                    'start',
                    'end',
                    'freePlaces',
                    'participants'
                  ]
                "></mat-row>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<button mat-fab style="z-index: 9998 !important" class="history-btn" color="primary" aria-label="Event hinzufügen"
  (click)="addEvent()" *ngIf="viewMode == enumViewMode.Admin && onlyEventsAdmin()">
  <mat-icon class="history-icon">add</mat-icon>
</button>