<h1 mat-dialog-title>{{ title }}</h1>
<mat-divider></mat-divider>
<div>
  <mat-form-field appearance="standard" style="font-size: 14px; width: 100%">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8"
      matSortActive="firstname"
      matSortDirection="desc"
    >
      <!--Selection -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="
              $event ? selection.toggle(row) : null; sortSelectedToTop()
            "
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!--first name-->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 15px">
          {{ getPrettyPrintedFirstName(row) }}
        </td>
      </ng-container>

      <!--last name-->
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
        <td mat-cell *matCellDef="let row">
          {{ getPrettyPrintedLastNameName(row) }}
        </td>
      </ng-container>

      <!--timespan-->
      <!-- https://angular.io/guide/inputs-outputs-->
      <!-- <ng-container matColumnDef="timespan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeitspanne</th>
        <td mat-cell *matCellDef="let row" style="max-width: 150px;">
          <time-range-picker
            *ngIf="selection.isSelected(row)"
            [start]="asDate(row?.start, true)"
            [end]="asDate(row?.end)"
            [rangeStart]="timestampToDate(data?.workPackage.start)"
            [rangeEnd]="timestampToDate(data?.workPackage.end)"
            (timeChanged)="timeChanged($event, row)"
          ></time-range-picker>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- <tr
        mat-row
        (click)="clickedRows.add(row); selection.toggle(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns"
      ></tr> -->

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keine Einträge vorhanden</td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      aria-label=""
    ></mat-paginator>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="set()"
    [disabled]="!changedTimesValid"
  >
    Speichern
  </button>
</div>
