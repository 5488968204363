import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { EventEmitterService } from './event-emitter.service';
import {
  CommonFunctionsService,
  DialogType,
} from './services/common-functions.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  DataModelService,
  WorkHourEntryAction,
  WorkHourEntryReason,
} from './services/data-model.service';
import { Subscription } from 'rxjs';
import {
  Sub_EncouragementPoints,
  Users,
  Sub_WorkHours,
  EncouragementPointEntryDialogIntention,
  EncouragementPointEntryDialogResult,
  ServiceUserEmail,
  WorkHourEntryDialogIntention,
  WorkHourEntryDialogResult,
  SetTestSystemActive,
  SetTestSystemUsedByAdmin,
  IsTestSystemActive,
  HiddenFunctionsPW,
  ISettings,
  TestSystemPW,
  TestSystemPWAdmin,
  User,
  Route,
  AppViewVisibilityIndex,
  RightEntity,
  GroupEntity
} from './services/definitions.service';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { AuthService } from './services/auth.service';
import { LogService } from './services/log.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { WorkHourEntryDialog } from './dialogs/work-hour-entry/work-hour-entry';
import { EncouragementPointEntryDialog } from './dialogs/encouragement-point-entry/encouragement-point-entry';
import {
  OneTimeAccessCodeDialog,
  OneTimeAccessCodeDialogData,
} from './dialogs/one-time-access-code/one-time-access-code';
import { Settings } from './services/definitions.service';
import { Platform } from '@angular/cdk/platform';
import { MessagingService } from './services/messaging.service';
import { UserRightsManagementService } from './services/user-rights-management.service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})


//TODO: Installation: App landet nicht mehr in der App-Liste



//TODO: Vergabe von Gruppenzugehoerigkeiten: zu definierten Zeitpunkten im Jahr. Z.b. nach der Forma Saison. automatisierter reminder
//TODO: ausgetretene Mitglieder beim Punkte-Transfer nicht betrachten


export class AppComponent {
  title = 'Residenz App';
  private userDataSub: Subscription;
  private usersWorkHoursSub: Subscription;
  private usersEncouragementPointsSub: Subscription;
  private protocolWorkHoursSub: Subscription;
  private settingsCollectionsSub: Subscription;
  public innerWidth: number;
  public isLoggedIn: boolean = false;
  public currentRouteName: string = '';
  public isIOsPlatform: boolean = false;
  public hideLoadingAnimation: boolean = false;
  public visibility = AppViewVisibilityIndex;
  private userData: User = undefined;

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private dataModel: DataModelService,
    private auth: AngularFireAuth,
    private authenticationService: AuthService,
    private commonFunctions: CommonFunctionsService,
    private eventEmitterService: EventEmitterService,
    private firestore: AngularFirestore,
    private log: LogService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private platform: Platform,
    private messagingService: MessagingService,
    private userRightsManagemetService: UserRightsManagementService
  ) {
    this.subscribeOnSiteUpdateEvent();

    //checked if logged in. If yes, load data
    this.auth.onAuthStateChanged((user) => {
      //clear all cache data
      eventEmitterService.clearAllData();
      this.isLoggedIn = false;
      if (user) {
        //check if mail is verified is done in login.component
        //info ignore email verification property (user.emailVerified)

        //ignore service user login
        if (user.email != ServiceUserEmail) {
          //Clear old logs if necessary
          this.log.deleteOldLogs();
          // Assign the data to the data source for the table to render
          this.subscribeOnUserDataChange(user.uid, this.preCheckAfterLogin);
          this.subscribeOnWorkHoursChange(user.uid);
          this.subscribeOnEncouragementPointsChange(user.uid);
          this.loadAccountingYearData();
          this.subscribeOnSettingsChange();
          this.isLoggedIn = true;
          this.authenticationService.userDetails = user;
          this.userRightsManagemetService.init(this.authenticationService.userId);

          // Push notification
          this.messagingService.requestPermission();
          this.messagingService.receiveMessage();
        }
      }
    });

    //subscribe on change of current route
    router.events.subscribe((val) => {
      this.getCurrentRouteName(val);
    });

    this.isIOsPlatform = platform.IOS || platform.SAFARI;

    //hide loading animation when finished loading
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart:
          break;
        case event instanceof NavigationEnd:
          this.hideLoadingAnimation = true;
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy() {
    this.unsubscribeEventEmitterListener();
  }

  public goToHome() {
    this.router.navigate(['']);
  }

  //Registriert sich auf Änderungen in den user daten
  private subscribeOnUserDataChange(
    userId: string,
    preCheckFunction: any = null
  ) {
    this.userDataSub = this.firestore
      .collection(Users())
      .doc(userId)
      .valueChanges({ idField: 'id' })
      .subscribe(
        (data: any) => {
          if (preCheckFunction != null) {
            var loggedIn = this.authenticationService.isLoggedIn;
            if (loggedIn) {
              var res = preCheckFunction(data);
              if (!res) {
                this.isLoggedIn = false;
                var text =
                  'Du bist kein Vereinsmitglied mehr und darfst die App leider nicht mehr verwenden';
                this.log.info(text, userId, AppComponent.name);
                setTimeout(() => {
                  this.commonFunctions.showErrorToast(text);
                }, 1000);
                this.authenticationService.blockLogin(text);
                this.authenticationService.logout();
                return;
              }
            }
          }
          this.userData = data;
          this.eventEmitterService.callUserDataLoaded(data);
        },
        (error) => {
          if (this.authenticationService.isLoggedIn) {
            let msg =
              'User wurde nicht in der Datenbank gefunden. Bitte kontaktiere den Admin.';
            this.log.error(msg, userId, error, AppComponent.name);
            this.commonFunctions.showErrorToast(msg, 10000);
          }
        }
      );
  }

  //Registriert sich auf Änderungen in Arbeitsstunden eines user
  private subscribeOnWorkHoursChange(uid: string) {
    this.usersWorkHoursSub = this.firestore
      .collection(Users())
      .doc(uid)
      .collection(Sub_WorkHours())
      .valueChanges()
      .subscribe(
        (data) => {
          // console.log('Users WorkHours data have changed');
          this.eventEmitterService.callWorkHoursDataLoaded(data);
        },
        (error) => {
          if (this.authenticationService.isLoggedIn) {
            let msg = 'Stream zur Abfrage auf der Arbeitsstunden abgebrochen';
            this.log.error(msg, error, AppComponent.name);
            this.commonFunctions.showErrorToast(msg);
          }
        }
      );
  }

  //Registriert sich auf Änderungen in Bonuspunkte eines user
  private subscribeOnEncouragementPointsChange(uid: string) {
    this.usersEncouragementPointsSub = this.firestore
      .collection(Users())
      .doc(uid)
      .collection(Sub_EncouragementPoints())
      .valueChanges()
      .subscribe(
        (data) => {
          // console.log('Users Encouragement Points data has changed');
          this.eventEmitterService.callEncouragementPointsDataLoaded(data);
        },
        (error) => {
          if (this.authenticationService.isLoggedIn) {
            let msg = 'Stream zur Abfrage auf der Bonuspunkte abgebrochen';
            this.log.error(msg, error, AppComponent.name);
            this.commonFunctions.showErrorToast(msg);
          }
        }
      );
  }

  //Laedt die Daten des aktuellen Abrechnungsjahrs der Arbeitsstunden
  private loadAccountingYearData() {
    this.dataModel.loadCurrentAccountingYear().then(
      (value) => {
        this.eventEmitterService.callAccountingYearDataLoaded(value);
        if (value.showWarning) {
          this.commonFunctions.showToastMessage(
            DialogType.INFO,
            'Abrechnungsjahr der Arbeitsstunden ist nicht aktuell. Bitte informiere den Administrator',
            10000
          );
        }
      },
      (rejectedReason) => {
        this.commonFunctions.showErrorToast(
          'Daten des Abrechnungsjahr konnten nicht geladen werden: ' +
          rejectedReason
        );
      }
    );
  }

  //Raised when the data on the server has changed
  private subscribeOnSiteUpdateEvent() {
    this.swUpdate.available.subscribe((event) => {
      window.location.reload();
      // this.dialog
      //   .open(UpdateAvailableComponent, {
      //     width: '350px',
      //     data: { result: 0 },
      //   })
      //   .afterClosed()
      //   .subscribe((result: any) => {
      //     if (result.result == 1) {
      //       window.location.reload();
      //     }
      //   });
    });
  }

  //Determines current route name
  private getCurrentRouteName(currentRoute) {
    var ret = '';
    var routeName: string | undefined = currentRoute?.routerEvent?.url;
    if (routeName) {
      switch (routeName) {
        case '/' + Route.POINTS_ACCOUNT_ADMIN.toString():
          ret = 'Förderpunkte verwalten';
          break;
        case '/' + Route.WORK_HOURS_ADMIN.toString():
          ret = 'Arbeitsstunden verwalten';
          break;
        case '/home':
        case '/':
          ret = 'Förderpunkte';
          break;
        case '/' + Route.ID_CARD.toString():
          ret = 'Mitgliedsausweis';
          break;
        case '/' + Route.WORK_HOURS.toString():
          ret = 'Arbeitsstunden';
          break;
        case '/' + Route.SETTINGS.toString():
          ret = 'Einstellungen';
          break;
        case '/' + Route.POINT_SEND.toString():
          ret = 'Punkte einlösen';
          break;
        case '/' + Route.POINT_RECEIVE.toString():
          ret = 'Punkte einsammeln';
          break;
        case '/' + Route.STATISTICS.toString():
          ret = 'Statistik';
          break;
        case '/' + Route.PROTOCOL_VIEW.toString():
          ret = 'Protokoll';
          break;
        case '/' + Route.EVENTS_ADMIN.toString():
          ret = 'Event-Planung';
          break;
        case '/' + Route.CALENDAR.toString():
          ret = 'Kalender';
          break;
        case '/' + Route.EVENTS.toString():
          ret = 'Events';
          break;
      }
    }
    this.currentRouteName = ret;
  }

  //performs a logout
  public logout() {
    this.authenticationService.logout();
  }

  //Executes a pre check after user data have been fetched
  private preCheckAfterLogin(userData: any): boolean {
    if (userData) {
      //Check ob Austrittsdatum vorhanden und bereits ueberschritten
      if ('membership' in userData) {
        var subData = userData.membership;

        if ('leavingDate' in subData && subData.leavingDate != 0) {
          var leaveDate = new Date(subData.leavingDate.seconds * 1000);
          var currentDate = new Date();
          if (leaveDate.getTime() < currentDate.getTime()) {
            return false;
          }
        }
      }
    }
    return true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  private unsubscribeEventEmitterListener() {
    if (this.userDataSub) this.userDataSub.unsubscribe();
    if (this.protocolWorkHoursSub) this.protocolWorkHoursSub.unsubscribe();
    if (this.usersWorkHoursSub) this.usersWorkHoursSub.unsubscribe();
    if (this.usersEncouragementPointsSub)
      this.usersEncouragementPointsSub.unsubscribe();
    if (this.settingsCollectionsSub) this.settingsCollectionsSub.unsubscribe();
  }

  //Navigates to work hour admin page if not in mobile mode, opens a dialog in other case
  public navigateToWorkHoursAdmin() {
    if (this.deviceService.isMobile()) {
      this.dialog.open(WorkHourEntryDialog, {
        width: '550px',
        data: {
          result: WorkHourEntryDialogResult.OK,
          dialogIntention: WorkHourEntryDialogIntention.ADD_MOBILE,
          dialogTitle: 'Arbeitsstunden hinzufügen/entnehmen',
          descriptionText: 'Bitte gib nun die Details ein',
          buttonNoName: 'Abbrechen',
          buttonYesName: 'Eintragen',
          comment: '',
          action: WorkHourEntryAction.ADD,
          reason: WorkHourEntryReason.STANDARD,
          amount: 0,
          maxAmount: 1000, //inital value (will be overwritten)
        },
        autoFocus: false,
      });
    } else {
      this.router.navigate(['/working-hour-admin']);
    }
  }

  //Navigates to point accounts admin page if not in mobile mode, opens a dialog in other case
  public navigateToPointAccountsAdmin() {
    if (this.deviceService.isMobile()) {
      this.dialog.open(EncouragementPointEntryDialog, {
        width: '550px',
        data: {
          result: EncouragementPointEntryDialogResult.OK,
          dialogIntention: EncouragementPointEntryDialogIntention.ADD_MOBILE,
          dialogTitle: 'Förderpunkte hinzufügen/entnehmen',
          descriptionText: 'Bitte gib nun die Details ein',
          buttonNoName: 'Abbrechen',
          buttonYesName: 'Eintragen',
          comment: '',
          action: WorkHourEntryAction.ADD,
          reason: WorkHourEntryReason.STANDARD,
          amount: 0,
          maxAmount: 1000, //inital value (will be overwritten)
        },
        autoFocus: false,
      });
    } else {
      this.router.navigate(['/point-accounts-admin']);
    }
  }

  //Opens the privacy page
  public openPrivacyPage() {
    window.open('https://www.residenz.club/datenschutz', '_blank');
  }

  //Opens the privacy page
  public openHelpPage() {
    var mailText =
      'mailto:help@residenz-app.club+?subject=Helpline [ResidenzApp]&body=Ich habe eine Frage zur App und brauche Hilfe zum folgenden Thema:\n';
    window.location.href = mailText;
  }

  //Is Test System active
  public isTestingSystemActive() {
    return IsTestSystemActive();
  }

  //Change to test system
  public changeToTestSystem() {
    this.openAccessCodeEntryDialog();
  }

  //change to production system
  public changeToProductionSystem() {
    SetTestSystemUsedByAdmin(false);
    SetTestSystemActive(false);
  }

  public reloadPage() {
    window.location.reload();
  }

  // Opens a dialog to enter a password to change to test system
  private openAccessCodeEntryDialog() {
    var data: OneTimeAccessCodeDialogData = {
      result: '',
      userEmail: '',
      title: 'Code eingeben',
      codeLength: 4,
    };
    this.dialog
      .open(OneTimeAccessCodeDialog, {
        width: '550px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((data: OneTimeAccessCodeDialogData) => {
        if (data?.result != undefined) {
          if (data.result != undefined && parseInt(data.result) != undefined) {
            var code = +data.result;

            //check PW
            if (code == TestSystemPW) {
              SetTestSystemActive(true);
              SetTestSystemUsedByAdmin(false);
            } else if (code == TestSystemPWAdmin) {
              SetTestSystemActive(true);
              SetTestSystemUsedByAdmin(true);
            } else {
              this.commonFunctions.showErrorToast(
                'Der eingegebene Code ist falsch'
              );
            }
          }
        }
      });
  }

  //Opens a dialog to enter code to access hidden functions
  public openHiddenFunctionAccessCodeEntry() {
    var data: OneTimeAccessCodeDialogData = {
      result: '',
      userEmail: '',
      title: 'Code eingeben',
      codeLength: 8,
    };
    this.dialog
      .open(OneTimeAccessCodeDialog, {
        width: '550px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((data: OneTimeAccessCodeDialogData) => {
        if (data?.result != undefined) {
          if (data.result != undefined && parseInt(data.result) != undefined) {
            var code = +data.result;
            //check PW
            if (code == HiddenFunctionsPW) {
              this.router.navigate(['settings']);
            } else {
              this.commonFunctions.showErrorToast(
                'Der eingegebene Code ist falsch'
              );
            }
          }
        }
      });
  }

  //Listens to changes on the point transfer collection
  private subscribeOnSettingsChange() {
    this.settingsCollectionsSub = this.firestore
      .collection(Settings())
      .valueChanges({ idField: 'id' })
      .subscribe(
        (data: any[]) => {
          if (data?.length > 0) {
            var settings: ISettings = data[0];
            // console.log('Settings have changed.');

            if (isDevMode()) {
              return;
            }

            //maintenance mode
            if (settings.maintenanceMode) {
              this.commonFunctions.showPermanentToast('Wartungsarbeiten');
            } else {
              this.commonFunctions.hidePermanentToast();
            }
          }
        },
        (error) => {
          this.log.error(
            `Failed on subscription to settings collection changes. Error: ${error}`
          );
        }
      );
  }

  public isVisible(index: AppViewVisibilityIndex): boolean {
    switch (index) {
      case AppViewVisibilityIndex.PointsReceiverMenuItem:
        return this.userRightsManagemetService.hasRight(RightEntity.FCT_RECEIVE_ENC_POINTS.valueOf());
      case AppViewVisibilityIndex.EventsAdminMenuItem:
        return this.authenticationService.isShiftOrganizerOrEventsAdmin || this.userRightsManagemetService.isPartOfAdminGroup();
      case AppViewVisibilityIndex.WorkHoursMenuItem:
        return this.authenticationService.participatesEncModel;
      case AppViewVisibilityIndex.ManageEncPointsMenuItem:
        return this.userRightsManagemetService.isPartOfAdminGroup() || this.userRightsManagemetService.isPartOfGroup(GroupEntity.ORGA_MANAGER) || this.userRightsManagemetService.hasRight(RightEntity.ENC_POINT_ADMIN_ROUTE_ACCESS.valueOf());
      case AppViewVisibilityIndex.ManageWorkHoursMenuItem:
        return this.userRightsManagemetService.isPartOfAdminGroup() || this.userRightsManagemetService.isPartOfGroup(GroupEntity.ORGA_MANAGER) || this.userRightsManagemetService.hasRight(RightEntity.WORK_HOUR_ADMIN_ROUTE_ACCESS.valueOf());
      case AppViewVisibilityIndex.StatisticMenuItem:
        return this.userRightsManagemetService.isPartOfAdminGroup() || this.userRightsManagemetService.isPartOfGroup(GroupEntity.CLUB_EXECUTIVE);
      default:
        return false;
    }
  }
}
